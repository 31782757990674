import slugify from 'slugify'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js'
import Alpine from 'alpinejs'
import Choices from 'choices.js'

window.Alpine = Alpine
Alpine.start()

let slugOptions = {
    replacement: '-',     // replace spaces with replacement character, defaults to `-`
    remove: undefined, // remove characters that match regex, defaults to `undefined`
    lower: true,          // convert to lower case, defaults to `false`
    strict: true,         // strip special characters except replacement, defaults to `false`
    locale: 'fr',         // language code of the locale to use
    trim: true            // trim leading and trailing replacement chars, defaults to `true`
}


/**
 * Initialise les tooltips de Bootstrap
 */
let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
})


/**
 * Fermeture automatique des alertes
 */
let dismissibleAlert = document.querySelector(".alert-dismissible")
if(dismissibleAlert) {
    window.setTimeout(() => {
        bootstrap.Alert.getOrCreateInstance(dismissibleAlert).close();
    }, 1800)
}


/**
 * Transforme automatiquement une chaîne d'un champ, en slug dans un autre champ.
 */
let toSlug = document.getElementById('toSlug');
if(toSlug) {
    toSlug.addEventListener('change', () => {
        let name = toSlug.value.replace(/<[^>]*>?/gm, '')
        let slug = slugify(name, slugOptions)
        let slugified = document.getElementById('slugified');
        slugified.value = slug;
    })
}


/**
 * Initialisation de choices
 */
 const choiceEl = document.querySelector('.js-choice')
 if(choiceEl) {
     const choices = new Choices(choiceEl, {
         duplicateItemsAllowed: false,
         placeholderValue: 'Cliquez pour choisir une étiquette',
         removeItemButton: true,
         loadingText: 'Chargement...',
         noResultsText: 'Aucun(s) résultat(s)',
         noChoicesText: 'Aucun choix disponible',
         itemSelectText: 'Cliquez pour choisir',
     })
 }
